<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg">
            <v-col cols="12">
              <h3>
                ฟอร์มกรอกข้อมูลสิทธิพิเศษ
              </h3>
              <v-divider class="mt-3" />
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="pt-2 pb-1">
              <p class="mb-1">
                ชื่อสิทธิพิเศษ <span class="primary--text">(TH)</span>
              </p>
              <v-text-field
                v-model="formData.name"
                placeholder="โค้ดส่วนลด 500 บาท"
                :rules="textBoxRules"
                color="primary"
                outlined
                dense
                required
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8"
              class="pt-2 pb-1">
              <p class="mb-1">
                ชื่อสิทธิพิเศษ <span class="primary--text">(EN)</span>
              </p>
              <v-text-field
                v-model="formData.translate.name"
                placeholder="Discount 500 ฿"
                :rules="textBoxRules"
                color="primary"
                outlined
                dense
                required
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                โค้ด
              </p>
              <v-text-field
                v-model="formData.code"
                :rules="textBoxRules"
                placeholder="ATT24"
                color="primary"
                outlined
                dense
                required
                hide-details
                :disabled="loading"
                append-icon="mdi-dice-multiple-outline"
                @click:append="randomCode()" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                ราคาทัวร์ขั้นต่ำ
              </p>
              <v-text-field
                v-model.number="formData.minPrice"
                color="primary"
                outlined
                dense
                required
                hide-details
                append-icon="mdi-currency-thb"
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                มูลค่า
              </p>
              <v-text-field
                v-model.number="formData.amount"
                color="primary"
                outlined
                dense
                required
                hide-details
                :disabled="loading"
                :append-icon="discountIcon()"
                @click:append="changeDiscountType()" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                ระดับสมาชิกที่ใช้ได้
              </p>
              <v-autocomplete
                v-model="formData.forRank"
                :items="ranks"
                item-text="text"
                item-value="value"
                outlined
                dense
                placeholder="โปรดเลือกสิทธิพิเศษ"
                :disabled="loading"
                hide-details>
                <template #selection="{ item }">
                  <span class="text-capitalize">
                    {{ item.text }}
                  </span>
                </template>
                <template #item="{ item }">
                  <span class="text-capitalize">
                    {{ item.text }}
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                คำอธิบาย <span class="primary--text">(TH)</span>
              </p>
              <v-text-field
                v-model="formData.description"
                color="primary"
                outlined
                dense
                required
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                คำอธิบาย <span class="primary--text">(EN)</span>
              </p>
              <v-text-field
                v-model="formData.translate.description"
                color="primary"
                outlined
                dense
                required
                hide-details
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                ระยะเวลาการใช้คูปอง
              </p>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="5">
                  <div
                    class="d-flex flex-row align-center"
                    style="gap: 0.5rem;">
                    <DatePickerDialog
                      v-model="formData.startDate"
                      :min="today"
                      :disabled="loading"
                      @input="selectDate($event)" />
                    <TimePickerDialog
                      v-model="formData.startDate"
                      :min="today"
                      :disabled="loading" />
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="2">
                  <div class="text-center my-2 mt-md-2 mb-md-0">
                    ถึง
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="5">
                  <div
                    class="d-flex flex-row align-center"
                    style="gap: 0.5rem;">
                    <DatePickerDialog
                      v-model="formData.endDate"
                      :min="formData.startDate || today"
                      :disabled="loading" />
                    <TimePickerDialog
                      v-model="formData.endDate"
                      :min="formData.startDate || today"
                      :disabled="loading" />
                  </div>
                </v-col>
              </v-row>
              <v-divider class="mt-3" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                จำนวนโค้ดโปรโมชั่น
              </p>
              <v-radio-group
                v-model="formData.isLimit"
                hide-details
                class="mt-0">
                <v-radio :value="true">
                  <template #label>
                    <v-row align="center">
                      <v-col cols="auto">
                        จำนวนโค้ดโปรโมชั่น
                      </v-col>
                      <v-col cols="auto">
                        <v-text-field
                          v-if="formData.isLimit"
                          v-model.number="formData.maxUsed"
                          :rules="formData.isLimit ? textBoxRules : []"
                          placeholder="ใส่จำนวนคูปองที่ใช้ได้"
                          type="number"
                          color="primary"
                          outlined
                          dense
                          required
                          hide-details />
                      </v-col>
                    </v-row>
                  </template>
                </v-radio>
                <v-radio :value="false">
                  <template #label>
                    <span>
                      ไม่จำกัดจำนวน
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                จำนวนการใช้งาน / คน
              </p>
              <v-radio-group
                v-model="formData.isLimitPerMember"
                hide-details
                class="mt-0">
                <v-radio :value="true">
                  <template #label>
                    <v-row align="center">
                      <v-col cols="auto">
                        จำนวนการใช้งาน / คน
                      </v-col>
                      <v-col cols="auto">
                        <v-text-field
                          v-if="formData.isLimitPerMember"
                          v-model.number="formData.perMember"
                          :rules="formData.isLimitPerMember ? textBoxRules : []"
                          placeholder="ใส่จำนวนคูปองที่ใช้ได้"
                          type="number"
                          color="primary"
                          outlined
                          dense
                          required
                          hide-details />
                      </v-col>
                    </v-row>
                  </template>
                </v-radio>
                <v-radio :value="false">
                  <template #label>
                    <span>
                      ไม่จำกัดจำนวน
                    </span>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                เงื่อนไข <span class="primary--text">(TH)</span>
              </p>
              <Editor
                v-model="formData.conditions"
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                เงื่อนไข <span class="primary--text">(EN)</span>
              </p>
              <Editor
                v-model="formData.translate.conditions"
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                วิธีการใช้โค้ด <span class="primary--text">(TH)</span>
              </p>
              <Editor
                v-model="formData.howTo"
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="8">
              <p class="mb-1">
                วิธีการใช้โค้ด <span class="primary--text">(EN)</span>
              </p>
              <Editor
                v-model="formData.translate.howTo"
                :disabled="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import MemberRanksProvider from '@/resources/member-ranks.provider'
import PrivilegesProvider from '@/resources/privileges.provider'
import DatePickerDialog from '@/components/DatePickerDialog'
import TimePickerDialog from '@/components/TimePickerDialog'
import Editor from '@/components/Editor.vue'

const MemberRanksService = new MemberRanksProvider()
const PrivilegesService = new PrivilegesProvider()

export default {
  components: {
    DatePickerDialog,
    TimePickerDialog,
    Editor
},
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    today: new Date().toISOString(),
    formData: {
      name: '',
      imageUrls: [],
      description: '',
      specialText: '',
      startDate: dayjs().format('YYYY-MM-DD HH:mm'),
      endDate: dayjs().format('YYYY-MM-DD HH:mm'),
      code: '',
      minPrice: 0,
      maxPrice: 0,
      amount: 0,
      type: 'privilege',
      discountType: 'baht',
      firstPurchase: false,
      perMember: -1,
      forRank: '',
      forMembers: [],
      forCountries: [],
      conditions: '',
      howTo: '',
      translate: {
        name: '',
        description: '',
        specialText: '',
        conditions: '',
        howTo: ''
      },
      isLimit: false,
      isLimitPerMember: false
    },
    ranks: []
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    }
  },
  watch: {
    'formData.isLimit': {
      handler (val) {
        if (val) {
          this.formData.maxUsed = this.formData.maxUsed === -1 ? 1 : this.formData.maxUsed
        } else {
          this.formData.maxUsed = -1
        }
      },
      deep: true
    },
    'formData.isLimitPerMember': {
      handler (val) {
        if (val) {
          this.formData.perMember = this.formData.perMember === -1 ? 1 : this.formData.perMember
        } else {
          this.formData.perMember = -1
        }
      },
      deep: true
    }
  },
  async mounted () {
    await this.getRanks()

    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'SettingsMember' })
    },
    discountIcon () {
      return this.formData.discountType === 'percent' ? 'mdi-percent-outline' : 'mdi-currency-thb'
    },
    changeDiscountType () {
      this.formData.discountType = this.formData.discountType === 'percent' ? 'baht' : 'percent'
    },
    selectDate (val) {
      this.formData.endDate = this.$dayjs(val).isAfter(this.$dayjs(this.formData.endDate))
        ? this.$dayjs(val).add(1, 'd').toISOString()
        : this.formData.endDate
    },
    async randomCode () {
      try {
        this.loading = true

        const { data } = await PrivilegesService.randomCode()

        this.formData.code = data
      } catch (error) {
        console.error('randomCode', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getRanks () {
      try {
        this.loading = true

        const { data } = await MemberRanksService.getItems({
          page: 1,
          limit: 9999,
          sortOrder: 'asc'
        })

        this.ranks = data.results.reduce((arr, r) => [
            ...arr,
            {
              text: r.name,
              value: r.name
            }
          ],
          [
            {
              text: 'ใช้ได้ทุกระดับ',
              value: ''
            }
          ])
      } catch (error) {
        console.error('getRanks', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await PrivilegesService.getItemById(this.$route.params.id)

        this.formData = {
          ...data,
          isLimit: data.maxUsed !== -1,
          isLimitPerMember: data.perMember !== -1
        }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        if (!this.isEdit) {
          await PrivilegesService.create(this.formData)
        } else {
          await PrivilegesService.updateItemById(this.formData)
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'SettingsMember' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>

<style>
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow  {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
